import type { Auth, User, UserCredential } from "firebase/auth"
import type { Functions } from "firebase/functions"
import { getApp, initializeApp } from "firebase/app"
import { getAnalytics, setUserId } from "firebase/analytics"
import {
  connectFirestoreEmulator,
  getFirestore,
  onSnapshot,
  doc,
  query,
  collection,
  where,
  getDoc,
} from "firebase/firestore"
import { connectAuthEmulator, getAuth, signInWithCustomToken } from "firebase/auth"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"

/*
import productionConfig from "@/config/firebase.init.production.json"
import stagingConfig from "@/config/firebase.init.staging.json"
import staging2Config from "@/config/firebase.init.staging2.json"
*/

let sbsc
let loginToken
let isTest: number = 0

export async function init() {
  // 予約済み URL を使って SDK を初期化
  const response = await fetch("/__/firebase/init.json")
  if (response.ok) {
    var json = await response.json()
    initializeApp(json)
  } else {
    throw new Error("firebase の初期化に失敗しました。")
  }

  /*
  // ロードバランサ等を利用していて予約済み URL が利用できない場合
  if (import.meta.env.MODE == "production" || import.meta.env.VITE_FIREBASE_CONFIG == "production") {
    initializeApp(productionConfig)
  } else if (import.meta.env.MODE == "staging" || import.meta.env.VITE_FIREBASE_CONFIG == "staging") {
    initializeApp(stagingConfig)
  } else if (import.meta.env.MODE == "staging2" || import.meta.env.VITE_FIREBASE_CONFIG == "staging2") {
    initializeApp(staging2Config)
  }
  */

  if (import.meta.env.VITE_USE_LOCAL) {
    console.warn("Firebase: use LocalEmulator")
    // ローカル環境でエミュレータを使う
    try {
      connectAuthEmulator(getAuth(), `http://${window.location.hostname}:9099`)
      connectFirestoreEmulator(getFirestore(), window.location.hostname, 8080)
      connectFunctionsEmulator(getFunctionsInTokyoRegion(), window.location.hostname, 5001)
    } catch {
      // HMR でエラーが出ることがあるので無視
    }
  }
}

/**
 * Analytics にユーザー情報を設定します。
 *
 * @param user ユーザー情報
 */
export function setAnalyticsUser(user: User) {
  setUserId(getAnalytics(), user.uid)
}

/**
 * Cloud Functions の東京リージョンのインスタンスを取得します。
 *
 * @returns 東京リージョンのインスタンス。
 */
export function getFunctionsInTokyoRegion(): Functions {
  return getFunctions(getApp(), "asia-northeast1")
}

/**
 * 認証 API のレスポンス。
 */
type SignInResponse = {
  result: string
  message?: string
  token?: string
  isTest?: number
}

/**
 * コード認証でログインします。
 *
 * @param auth Firebase Authentication
 * @param code コード
 * @returns 認証情報を返します。
 */
export async function signInWithCode(auth: Auth, code: string): Promise<number> {
  const functions = getFunctionsInTokyoRegion()
  const method = httpsCallable<{ code: string }, SignInResponse>(functions, "verifyCode")

  const res = await method({ code })
  if (res.data.result !== "success") {
    throw new Error(res.data.message)
  }
  loginToken = res.data.token
  isTest = res.data.isTest
  await signInWithCustomToken(auth, res.data.token)
    .then((signInResult) => {})
    .catch((e) => {
      throw new Error(e)
    })

  return isTest
}

export async function startWatchSession(user: User, onError) {
  console.log("startWatchSession uid: " + user.uid)
  var q = query(collection(getFirestore(), "sessions"), where("userId", "==", user.uid))

  sbsc = onSnapshot(q, (snapshot) => {
    snapshot.forEach((doc) => {
      //console.log("loginToken:" + loginToken)
      var session = doc.data()
      //console.log("session:" + session)
      if (session.customeToken != loginToken) {
        console.log("他のデバイスからログインされました")
        onError()
      }
    })
  })
}

export function stopWatchSession() {
  sbsc()
}

// maintenance status読み込み
export const MAINT_STATUS = {
  BEFORE_OPEN: 0,
  OPEN: 1,
  CLOSE: 2,
}

export async function getMaintStatus() {
  const db = getFirestore()
  const docRef = doc(db, "maint", "status")
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data().status
  }

  return MAINT_STATUS.BEFORE_OPEN
}
